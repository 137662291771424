
import { defineComponent, onMounted, ref, onBeforeMount } from "vue";
import * as Yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { sign } from "jsonwebtoken";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import { AxiosError } from "axios";
import invalidMails from "@/core/config/InvalidEmailsList";
import { useRoute, useRouter } from "vue-router";
import moment from "moment-timezone";

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data: () => ({
    selectedAts: "",
    subscriptionPlanId: "60d47b0f94e1130bd59de5b5",
    cardExpMonth: "",
    cardExpYear: "",
    plans: [],
    lastStep: {
      title: "Almost done",
      alertTitle: "Click submit",
      alertContent: 'Click "submit" to register your account.'
    },
    showPassword: false,
    env: process.env.NODE_ENV
  }),
  setup() {
    // created()\
    const isLoading = ref(true);
    const email = ref("");
    const code = ref("");
    const route = useRoute();
    const router = useRouter();
    const registration = Yup.object().shape({
      companyName: Yup.string()
        .required()
        .label("Company name"),
      firstName: Yup.string()
        .required()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      email: Yup.string()
        .email()
        .required()
        .label("Email")
        .test("checkEmail", "Email is already taken!", async function(value) {
          const checkEmail = data =>
            ApiService.post("/v1/user/email-exist", data);

          try {
            const { data } = await checkEmail({ email: value });
            if (data.success) {
              return false;
            }

            return true;
          } catch (error) {
            if (error) {
              return true;
            }
          }
          return true;
        })
        .test(
          "companyMailOnly",
          "Enter your business email (Not allowed: gmail, yahoo, etc)",
          async function(value) {
            if (value) {
              const domain = value.split("@");
              if (invalidMails.includes(domain[1])) {
                return false;
              }
            }
            return true;
          }
        ),
      password: Yup.string()
        .min(8)
        .required()
        .label("Password")

      // subscriptionPlanId: Yup.string()
      //   .required()
      //   .label("Subscription plan"),
      // cardName: Yup.string()
      //   .when("subscriptionPlanId", {
      //     is: val => val !== "60d47b0f94e1130bd59de5b5",
      //     then: Yup.string().required()
      //   })
      //   .label("Card holder name"),
      // cardNumber: Yup.string()
      //   .when("subscriptionPlanId", {
      //     is: val => val !== "60d47b0f94e1130bd59de5b5",
      //     then: Yup.string()
      //       .required()
      //       .min(15)
      //       .max(16)
      //   })
      //   .label("Card number"),
      // cardExpMonth: Yup.string()
      //   .when("subscriptionPlanId", {
      //     is: val => val !== "60d47b0f94e1130bd59de5b5",
      //     then: Yup.string().required()
      //   })
      //   .label("Month"),
      // cardExpYear: Yup.string()
      //   .when("subscriptionPlanId", {
      //     is: val => val !== "60d47b0f94e1130bd59de5b5",
      //     then: Yup.string().required()
      //   })
      //   .label("Year"),
      // cardCvv: Yup.string()
      //   .min(3)
      //   .max(4)
      //   .when("subscriptionPlanId", {
      //     is: val => val !== "60d47b0f94e1130bd59de5b5",
      //     then: Yup.string().required()
      //   })
      //   .label("CVV")
    });

    const registerAdmin = async values => {
      /* eslint-disable @typescript-eslint/camelcase */
      let token;
      // const referral_code = this.$route.query.ref;

      // if (values.subscriptionPlanId !== "60d47b0f94e1130bd59de5b5") {
      //   const cardData = JSON.parse(
      //     JSON.stringify({
      //       billing_details: {
      //         name: values.cardName,
      //         email: values.email,
      //         phone: values.contactNumber
      //       },
      //       card: {
      //         number: values.cardNumber,
      //         exp_month: values.cardExpMonth,
      //         exp_year: values.cardExpYear,
      //         cvc: values.cardCvv
      //       }
      //     })
      //   );

      //   // Wrap card data and unwrap in server
      //   token = await sign(cardData, process.env.VUE_APP_SECRET);
      // }

      const submitButton = document.querySelector(
        "#submit_registration"
      ) as HTMLElement;

      try {
        submitButton.setAttribute("data-kt-indicator", "on");
        submitButton.setAttribute("disabled", "disabled");

        // const router = this.$router;

        // Register Account
        const createAccount = data =>
          ApiService.post("/v1/user/register/super-admin", data);

        let account;

        console.log("data", values);
        if (
          store.getters.currentUser &&
          store.getters.currentUser.accessToken
        ) {
          account = store.getters.currentUser;
        } else {
          account = await createAccount({
            companyName: values.companyName,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            contactNumber: "",
            referral_code: "",
            code: code.value
          });

          account = account.data.data;
        }

        console.log({ account });

        // Login user
        store.commit(Mutations.SET_AUTH, account);
        ApiService.setHeader();

        // Create subscription
        // router.push({ name: "account-overview" });

        Swal.fire({
          title: "Account created",
          text: `Please click the button ok to continue!`,
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6"
        }).then(res => {
          if (res.isConfirmed) {
            window.location.href = "/";
          }
        });

        submitButton.removeAttribute("data-kt-indicator");
        submitButton.removeAttribute("disabled");
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "Unable to register.",
          buttonsStyling: false,
          confirmButtonText: "Try again",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }

      submitButton.removeAttribute("data-kt-indicator");
      submitButton.removeAttribute("disabled");
    };

    onBeforeMount(async () => {
      await store.dispatch(Actions.LOGOUT);
    });

    onMounted(async () => {
      const { token, who } = route.query;

      if (!token || !who) {
        isLoading.value = true;
        Swal.fire({
          icon: "error",
          title: "Warning",
          text: "Unauthorized!",
          showConfirmButton: false
        });

        return;
      }

      const verifyToken = (args: { invite_token: string; email: string }) =>
        ApiService.post("/v1/user/verify-token", args);

      const { data } = await verifyToken({
        invite_token: token as string,
        email: who as string
      });

      const updateInviteStatus = data =>
        ApiService.post("/v1/user/update-invite-status", data);
      if (data.success) {
        if (data.data.status !== "in-progress") {
          Swal.fire({
            title: "Warning",
            text: "Invalid Code!",
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
            onClose: () => {
              // Go to page after successfully login
              router.push({
                name: "sign-in"
              });
            }
          });
          return;
        }
        //if token is expired by checking dates
        const startTime = moment(data.data.createdAt);
        const endTime = moment();
        const duration = moment.duration(endTime.diff(startTime));
        const hours = duration.asHours();

        if (hours > 24) {
          isLoading.value = true;
          await updateInviteStatus({
            invite_token: token,
            email: who,
            status: "expired"
          });

          Swal.fire({
            title: "Warning",
            text: "Token is already exipired!",
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
            onClose: () => {
              router.push({
                name: "sign-in"
              });
            }
          });
          return;
        }

        await updateInviteStatus({
          invite_token: token,
          email: who,
          status: "accepted"
        });
        isLoading.value = false;
        email.value = who as string;
        code.value = token as string;
      }
    });
    return {
      registration,
      isLoading,
      email,
      registerAdmin
    };
  }
});
